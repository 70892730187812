<template>
  <b-modal
    ref="modalVoidTransaction"
    v-model="isShowModal"
    centered
    @show="show"
    @hidden="hide"
  >
    <template #modal-header>
      <div class="ft-white">Reject Receipt</div>
    </template>
    <div>
      <div>
        <InputSelect
          title="Reason"
          name="reasonId"
          isRequired
          :options="rejectReasonList"
          v-model="form.reasonId"
          valueField="id"
          textField="name"
          :isValidate="$v.form.reasonId.$error"
          :v="$v.form.reasonId"
        >
          <template v-slot:option-first>
            <b-form-select-option value="" disabled
              >Reason
            </b-form-select-option>
          </template>
        </InputSelect>
      </div>
      <transition name="component-fade" mode="out-in">
        <div v-if="form.reasonId == 5">
          <InputTextArea
            :rows="4"
            textFloat="Note"
            placeholder="Note"
            id="textarea-rows"
            v-model="form.note"
            isRequired
            :isValidate="$v.form.note.$error"
            :v="$v.form.note"
          />
        </div>
      </transition>
    </div>
    <template #modal-footer>
      <b-row>
        <b-col>
          <b-button class="btn-cancel" @click="hide"> Cancel </b-button>
        </b-col>
        <b-col>
          <b-button @click.prevent="confirm" class="btn-confirm">
            Confirm
          </b-button>
        </b-col>
      </b-row>
    </template>
  </b-modal>
</template>

<script>
import InputTextArea from "@/components/inputs/InputTextArea";
import InputSelect from "@/components/inputs/InputSelect";
import { requiredIf } from "vuelidate/lib/validators";

export default {
  components: {
    InputTextArea,
    InputSelect,
  },
  props: {},
  data() {
    return {
      isShowModal: false,
      form: {
        reasonId: "",
        note: "",
      },
      rejectReasonList: [],
    };
  },
  validations() {
    return {
      form: {
        reasonId: {
          required: requiredIf(function () {
            return !this.form.reasonId;
          }),
        },
        note: {
          required: requiredIf(function () {
            return this.form.reasonId == 5;
          }),
        },
      },
    };
  },
  created() {
    this.getStatusList();
  },
  methods: {
    show() {
      this.clearForm()
      this.isShowModal = true;
    },
    hide() {
      this.isShowModal = false;
    },
    confirm() {
      this.$v.form.$touch();
      if (this.$v.form.$error) {
        return;
      }
      this.$emit("confirm", this.form);
      this.hide();
    },
    async getStatusList() {
      let rejectReasonList = [];
      await this.$store.dispatch("getReceiptScanRejectReasonList");
      let data = await this.$store.state.receiptscan
        .receiptScanRejectReasonList;
      if (data.result == 1) {
        this.rejectReasonList = rejectReasonList.concat(data.detail);
      }
    },
    clearForm() {
      this.form = {
        reasonId: "",
        note: "",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .modal-header {
  background-color: var(--secondary-color);
}
.ft-black {
  color: #ffffff;
  font-weight: 600;
  font-size: 17px;
}
.btn-cancel {
  background-color: transparent;
  width: 100%;
  border-color: var(--primary-color);
  color: var(--primary-color);
}
.btn-confirm {
  background-color: var(--primary-color);
  color: #fff;
  width: 100%;
}
::v-deep .modal-footer {
  display: block;
}

.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
</style>
