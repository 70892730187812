<template>
  <div class="p-3">
    <b-row class="bg-tabs-custom">
      <b-col><span>Receipt Detail</span></b-col>
      <b-col class="text-right"
        ><span class="transaction-date"
          >Date/Time Transaction :
          {{ detail.created_time | moment($formatDateTime) }}
        </span>
      </b-col>
    </b-row>
    <b-row class="bg-white content-section">
      <b-col sm="4">
        <inner-image-zoom
          :src="detail.image_url"
          zoomType="hover"
          :zoomScale="0.5"
          hideHint
        />
      </b-col>
      <b-col sm="8">
        <b-row class="mb-3">
          <b-col class="label-transactions" sm="3"
            ><div>Buyer Name</div>
            <div>:</div></b-col
          >
          <b-col sm="9"
            >{{ detail.customer_name }}
            <button
              @click.prevent="
                $router.push(`/report/customer/${detail.user_guid}`)
              "
              class="icon-to-point"
            >
              <font-awesome-icon icon="chevron-right" size="xs" /></button
          ></b-col>
        </b-row>
        <b-row class="mb-3">
          <b-col class="label-transactions" sm="3">
            <div>Ref No.</div>
            <div>:</div>
          </b-col>
          <b-col sm="9">
            <InputText
              textFloat=""
              placeholder="Ref no."
              type="text"
              v-model="detail.invoice_no"
              isRequired
              :isValidate="v.invoice_no.$error"
              :v="v.invoice_no"
            />
          </b-col>
        </b-row>
        <b-row class="mb-3"
          ><b-col class="label-transactions" sm="3"
            ><div>Branch</div>
            <div>:</div></b-col
          ><b-col sm="9">
            <InputSelect
              name="type"
              valueField="id"
              textField="name"
              v-model="detail.branch_id"
              :options="branchList"
              :isValidate="v.branch_id.$error"
              :v="v.branch_id"
              @onDataChange="handleSelectBranch(detail.branch_id)"
            >
              <template v-slot:option-first>
                <b-form-select-option value="" disabled
                  >-- Please Select Branch --</b-form-select-option
                >
              </template>
            </InputSelect>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
import InnerImageZoom from "vue-inner-image-zoom";
export default {
  props: {
    detail: {
      required: true,
      type: Object,
    },
    v: {
      required: true,
      type: Object,
    },
  },
  components: {
    InputText,
    InputSelect,
    InnerImageZoom,
  },
  data() {
    return {
      branchList: [],
    };
  },
  created() {
    this.getBranchList();
  },
  methods: {
    async handleSelectBranch(value) {
      this.detail.branch_id = value;
    },
    async handleSelectProduct(value) {
      this.detail.branch_id = value;
    },
    async getBranchList() {
      const result = await this.axios(`/transactioncode/branch`);
      const branchListMapping = result.data.detail.map((branch) => {
        return { ...branch, name: `[${branch.plant_id}] ${branch.name}` };
      });
      this.branchList = branchListMapping;
    },
  },
};
</script>

<style lang="scss" scoped>
.bg-tabs-custom {
  text-align: left;
  border: none;
  background: var(--secondary-color);
  border-radius: 0px;
  color: var(--font-color);
  font-weight: 600;
  padding: 10px 0;
}

.ft-black {
  color: #000;
}

.consent-allow {
  color: #06c306;
}

.status-cancel {
  color: red;
}

.status-void {
  color: var(--primary-color);
}

.label-transactions {
  color: #000;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-section {
  padding: 15px 0;
  line-height: 23px;
  color: gray;
}

.icon-to-point {
  background-color: var(--primary-color);
  color: #fff;
  border: none;
  border-radius: 3px;
}

.transaction-date {
  color: var(--primary-color);
}

.status-return-1 {
  color: #cd3000;
}

.min-width-fix {
  min-width: 150px;
}
</style>
